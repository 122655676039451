import React from 'react';
import Helmet from 'react-helmet';

interface Props {
    description: string;
    pageTitle?: string;
    keywords?: string;
    url?: string;
    type?: string
}

export const Helm: React.FC<Props> = ({ description, pageTitle = '', keywords, url, type = "website" }) => {
    return (
        <Helmet>
            <title>{pageTitle ? pageTitle : 'iDream Interactive'}</title>
            <meta name="Description" content={description}></meta>
            <meta property="og:type" content={type} />
            <meta property="og:title" content={`${pageTitle ? pageTitle : 'iDream Interactive'}`} />
            <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/${url}`} />
            <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}/assets/embed.jpg`} />
            <meta name="twitter:image" content={`${process.env.GATSBY_SITE_URL}/assets/embed.jpg`} />
            <meta name="keywords" content={keywords} />
            <meta name="facebook-domain-verification" content="ahxeyxlk6e036w6aqum6r1jyzb2vvu" />
            <link rel='apple-touch-icon' href='/apple-touch-icon.png' />
            <link rel='shortcut icon' href='/favicon/icon-16x16.png' />
            <link rel='icon' type='image/png' sizes='32x32' href='/favicon/icon-32x32.png' />
            <link rel='icon' type='image/png' sizes='16x16' href='/favicon/icon-16x16.png' />
            <link rel='mask-icon' href='/favicon/icon-96x96.png' color='#004eff' />
            <script>var t_code = "DS-11179-3291-982";</script>
            <script src="//ds360.co/track/script.js"></script>
            <html lang="en" />
        </Helmet>
    );
};
