import React from "react";

interface Props {
    id: string,
    isBlue?: boolean
}

export const LargeButton: React.FC<Props> = ({ isBlue, id }) => {
    return (
        <svg width="250" height="55" viewBox="0 0 250 55" style={{ fill: isBlue ? `url(#${id})` : "" }}>
            <defs>
                <linearGradient id={`${id}`} gradientTransform="rotate(-10)">
                    <stop offset="0" stop-color="#004eff" />
                    <stop offset="100%" stop-color="#407aff" />
                </linearGradient>
            </defs>
            <path d="M248,0H125H24.7c-2.9,0-5.5,1.6-7,4.1L0.2,52.3c-0.3,0.9,0,2,0.8,2.4C1.4,54.9,1.7,55,2,55h123h100.3c2.9,0,5.5-1.6,7-4.1
                l17.5-48.3c0.3-0.9,0-2-0.8-2.4C248.6,0.1,248.3,0,248,0z"/>
        </svg>
    )
}